

// rect.bar
//   fill green

rect.bg
  fill gray
  opacity 0.15
text
  fill white
