
.source-code

  code
    font-size 0.75rem
    color $gray

  .line-number
    font-size 0.75rem
    margin 0 4em 0 0
    color $darkness

  .line
    margin 0 4em 0.25em 1em
