
@import '@/styles/variables.scss';

.contract-interaction {
  color: #fff;
  width: 100%;
  .tooltip .tooltip-text .trim-value .copy-icon svg {
    margin-top: 2px;
  }
  .wallet-connect-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .metamask-connection-message {
    color: $orange_900;
    text-decoration: underline;
    text-underline-offset: 2px;
    margin-bottom: 10px;
  }

  .metamask-link {
    color: #fff;
  }
  .btn-reload {
    border: 1px solid transparent;
    padding: 10px;
    animation: breathe 8s ease infinite;
    background-color: transparent;
    transition: color 0.3s ease;
  }
  .btn-reload:hover {
    color: $orange_900;
  }
  .btn-connect {
    border: 1px solid $newbw_700;
    padding: 10px;
  }

  .btn-disabled {
    border: 1px solid #5f5f5f;
    color: #5f5f5f;
    cursor: default;
  }

  .btn-disabled:hover {
    background-color: transparent;
  }

  .metamask-title {
    color: $cyan_300;
  }
  .methods-container {
    margin: 20px 0;
    .btn-content {
      display: flex;
      gap: 10px;
      .btn {
        font-size: 12px;
        border: 1px solid $newbw_700;
        padding: 8px;
      }
    }
    .methods-content {
      margin-top: 20px;
    }
  }

  .unverified-implementation-msg {
    margin-top: 20px;
  }

  .implementation-address-link {
    color: #bbb;
    transition: color 0.3s ease;
    display: flex;
    gap: 5px;
  }

  .accounts-changed-message-container {
    margin-top: 20px;

    .btn {
      margin-top: 10px;
      font-size: 12px;
      border: 1px solid $newbw_700;
      padding: 8px;
    }
  }

  @keyframes breathe {
  0% {
    box-shadow: 0 0 10px rgba(0, 255, 255, 0);
  }
  30% {
    box-shadow: 0 0 10px $orange_900;
  }
  70% {
    box-shadow: 0 0 10px $orange_900;
  }
  100% {
    box-shadow: 0 0 10px rgba(0, 255, 255, 0);
  }
}

.underline {
  text-decoration: underline;
}
}
