.block ul,
.transaction ul {
  list-style: none;
}
td.from,
th.from {
  text-align: right;
}
td.to,
th.to {
  text-align: left;
}
.blocks ul,
.block-data ul {
  margin: 0;
  padding: 0;
}
.blocks ul li,
.block-data ul li {
  list-style: none;
}
.block {
  flex: 1 1 100%;
  display: flex;
  flex-flow: row;
  border-left: solid 3px;
}
.block .block-icon {
  flex: 1;
}
.block .block-icon .svg-icon {
  width: 2em;
  height: 2em;
}
.block .block-number {
  margin-left: 0.25rem;
  font-size: 1.25em;
  font-weight: 400;
}
.centered {
  margin: 0 auto;
}
.home {
  display: flex;
}
.last-blocks {
  flex-flow: row wrap;
}
.auto-update {
  display: flex;
  justify-content: center;
  width: 100%;
}
.hero {
  margin-top: 2rem;
  max-width: 100%;
}
.last-block {
  display: flex;
}
.last-block ul.block-data {
  margin: 0;
  padding: 0;
  flex-flow: column nowrap;
}
.last-block ul.block-data li {
  width: 100% !important;
}
.pending-blocks {
  flex: 3;
}
.flex-table .data-field {
  margin: 0;
}
.data-field {
  justify-content: flex-start;
}
td .data-field {
  justify-content: center;
}
.hidden-ctrl {
  border: none;
  margin: 0;
  padding: 0;
  background-color: inherit;
  opacity: 0;
  width: 1px;
  height: 1px;
  position: absolute;
}
.tooltip,
.trim {
  position: relative;
  display: inline-flex;
  overflow: visible;
}
.tooltip {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.tooltip a {
  color: #b8b8b8;
}
.tooltip .copy-button .message {
  z-index: 100;
  color: #b8b8b8;
}
.tooltip .left-button {
  margin: 0 0 0 0.5em;
}
.tooltip .tip {
  will-change: transform opacity;
  position: absolute;
  filter: drop-shadow($tip-sh);
  width: 100%;
  color: #b8b8b8;
  display: flex;
  justify-content: flex-start;
  animation: 0.125s ease-in tooltip-anim;
  z-index: 50;
}
.tooltip .tip .value {
  border-radius: 3px;
  padding: 0.125em 0.25em;
  background-color: #121212;
  word-break: break-all;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
}
.tooltip .tip .tip-txt {
  padding: 0.25em;
  overflow: visible;
  display: inline;
  margin: 0;
  font-size: 0.9em;
  font-weight: normal;
}
.tooltip .tip:after,
.tooltip .tip:before {
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  z-index: 100;
}
.tooltip .tip:after {
  border-width: 5px;
}
.tooltip .tip:before {
  border-width: 6px;
}
.tooltip .tip.top:after,
.tooltip .tip.top:before {
  top: 100%;
  left: 50%;
}
.tooltip .tip.top:after {
  border-top-color: #121212;
  margin-left: -5px;
}
.tooltip .tip.top:before {
  border-top-color: ;
  margin-left: -6px;
}
.tooltip .tip.bottom:after,
.tooltip .tip.bottom:before {
  bottom: 100%;
  left: 50%;
}
.tooltip .tip.bottom:after {
  border-bottom-color: #121212;
  margin-left: -5px;
}
.tooltip .tip.bottom:before {
  border-bottom-color: ;
  margin-left: -6px;
}
.tooltip .tip.left:after,
.tooltip .tip.left:before {
  left: 100%;
  bottom: 50%;
}
.tooltip .tip.left:after {
  border-left-color: #121212;
  margin-bottom: -5px;
}
.tooltip .tip.left:before {
  border-left-color: ;
  margin-bottom: -6px;
}
.tooltip .tip.right:after,
.tooltip .tip.right:before {
  right: 100%;
  bottom: 50%;
}
.tooltip .tip.right:after {
  border-right-color: #121212;
  margin-bottom: -5px;
}
.tooltip .tip.right:before {
  border-right-color: ;
  margin-bottom: -6px;
}
.tooltip .trim a {
  color: #b8b8b8;
}
.tooltip .points {
  display: inline-flex;
  box-shadow: none;
  width: 1rem;
}
.tooltip .points button {
  height: auto;
  width: auto;
  min-height: 2em;
  color: #b8b8b8;
}
.tooltip .points button .icon,
.tooltip .points button .svg-icon {
  display: inline-flex;
  color: #b8b8b8;
  max-height: 1em;
  min-width: 1em;
  justify-content: center;
  align-items: center;
}
.tooltip .points.left {
  float: left;
  justify-content: flex-start;
}
.tooltip .points.right {
  float: right;
  justify-content: flex-end;
}
.tooltip .copy-txt {
  display: inline;
}
.tooltip button.close {
  line-height: 1em;
  height: 1em;
}
.tooltip button.close:after {
  top: 0.25em !important;
  right: 0.25em !important;
  border-radius: 50%;
  line-height: 1em;
  height: 1em;
  width: 1em;
  padding: 0.25em;
}
.head {
  display: block;
}
.anim {
  animation-duration: 0.5s;
  animation-name: copya;
  animation-timing-function: ease-in-out;
  opacity: 0;
}
@-moz-keyframes copya {
  0% {
    opacity: 0;
  }
  75% {
    transform: translateY(-1em);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-5em);
  }
}
@-webkit-keyframes copya {
  0% {
    opacity: 0;
  }
  75% {
    transform: translateY(-1em);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-5em);
  }
}
@-o-keyframes copya {
  0% {
    opacity: 0;
  }
  75% {
    transform: translateY(-1em);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-5em);
  }
}
@keyframes copya {
  0% {
    opacity: 0;
  }
  75% {
    transform: translateY(-1em);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-5em);
  }
}
.copying {
  animation-duration: 1s;
  animation-name: copyb;
}
@-moz-keyframes tooltip-anim {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  75% {
    transform: scale(1);
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes tooltip-anim {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  75% {
    transform: scale(1);
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes tooltip-anim {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  75% {
    transform: scale(1);
  }
  100% {
    opacity: 1;
  }
}
@keyframes tooltip-anim {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  75% {
    transform: scale(1);
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes copyb {
  0% {
    color: #b8b8b8;
  }
  100% {
    color: #b8b8b8;
  }
}
@-webkit-keyframes copyb {
  0% {
    color: #b8b8b8;
  }
  100% {
    color: #b8b8b8;
  }
}
@-o-keyframes copyb {
  0% {
    color: #b8b8b8;
  }
  100% {
    color: #b8b8b8;
  }
}
@keyframes copyb {
  0% {
    color: #b8b8b8;
  }
  100% {
    color: #b8b8b8;
  }
}
/*# sourceMappingURL=src/components/ToolTip.css.map */