

.waiting-dots
  .dot
    fill-color $color

  .dot-trans-enter-active, .dot-trans-leave-active
    transition fill-opacity 0.25s
    fill-opacity 1

  .dot-trans-enter, .dot-trans-leave-to
    fill-opacity 0
