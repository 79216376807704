.data-page {
  align-self: flex-start;
}
.page-header {
  width: 100%;
}
.data-page h2.title {
  text-transform: capitalize;
}
.messages {
  font-size: 0.9em;
  text-align: center;
}
/*# sourceMappingURL=src/components/DataPage.css.map */