
.files-ctrl
  box-sizing content-box
  min-width 100%

  .files-input
    display none

ul.files
  list-style none
  padding 0
  display flex
  flex-flow row wrap
  margin 1em 0 0 0

  li.file
    font-size 0.85em
    display flex
    flex 1
    display flex
    flex-flow row nowrap
    align-items center
    justify-content: center
    margin 0 0.5em

    button
      vertical-align middle
      height auto
      margin 0 0.5em

    .file-name
      margin 0 0 0 0.5em
