
.data-page
  position: relative

.table-ctrls
  top 0px
  right 24px
  position absolute
  justify-content flex-end

.unsortable > .field-title

  .icon svg
    fill gray !important

.sort

  button
    display flex

  div
    display flex

  .icon
    margin 0 0.5em 0 0

  .sort-icon
    margin 0 0.25em
    display flex
    justify-content center
    align-items center
    background $color
    width 1em
    height @width
    border-radius 50%

    svg.svg-icon *
      fill $bg-color !important
      display flex

sub

.has-sort
  padding 0 !important
