.burp-button {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.burp-button .svg-icon,
.burp-button .icon {
  width: 1.25em;
  height: 1.25em;
}
.burp-button .message {
  position: absolute;
  z-index: 10;
  bottom: -10px;
  opacity: 0;
  width: auto;
  white-space: nowrap;
}
.burp-button .anim {
  animation-duration: 0.5s;
  animation-name: rise-message;
  animation-timing-function: ease-in-out;
  opacity: 0;
}
@-moz-keyframes rise-message {
  0% {
    opacity: 0;
  }
  75% {
    transform: translateY(-1em);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-6em);
  }
}
@-webkit-keyframes rise-message {
  0% {
    opacity: 0;
  }
  75% {
    transform: translateY(-1em);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-6em);
  }
}
@-o-keyframes rise-message {
  0% {
    opacity: 0;
  }
  75% {
    transform: translateY(-1em);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-6em);
  }
}
@keyframes rise-message {
  0% {
    opacity: 0;
  }
  75% {
    transform: translateY(-1em);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-6em);
  }
}
/*# sourceMappingURL=src/components/controls/BurpButton.css.map */