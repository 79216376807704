
//colors
$newbw_900: #121212;
$newbw_800: #252525;
$newbw_700: #3A3A3A;

$purple_900: rgb(158, 118, 255);
$green_900: #79C600;
$pink_900: #FF71E1;
$orange_900: #FF9100;
$cyan_300: #08FFD0;
$yellow_900: rgb(113 63 18);
$lime_900: rgb(54 83 20);
$red_500: #ff2323;

$white_100: #FBFBFB;
$white_400: #B8B8B8;

// media queries
$phone: 600px;
$tablet: 990px;
$laptop: 1175px;
$desktop: 2000px;
