

.big-field
  display flex

  .decode
    margin-left 10px

  textarea
    padding 0
    font-size 0.75em
    font-family $monospace-font
    color inherit

  .field
    raw()
    width 100%
    max-width 100%
    min-height 3em
    background none
    color $color
    border none
    margin 0 0 2em 0
