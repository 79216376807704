
@import '@/styles/variables.scss';

.remix-link {
  padding: 8px 10px;
  font-size: 12px;
  border-radius: 10px;
  background-color: none;
  color: $white_100;
  border: 2px solid $white_400;
  transition: background-color 0.3s ease, border 0.3s ease;
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: 6px;
  margin-right: 3px;

  &:hover {
    border: 2px solid $white_100;
  }
}
