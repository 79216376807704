
.block, .transaction
  ul
    list-style none

td.from, th.from
  text-align right

td.to, th.to
  text-align left

.blocks, .block-data
  ul
    margin 0
    padding 0

    li
      list-style none

.block
  flex 1 1 100%
  display flex
  flex-flow row
  border-left solid 3px

  .block-icon
    flex 1

    .svg-icon
      width 2em
      height @width

  .block-number
    margin-left 0.25rem
    font-size 1.25em
    font-weight 400

.centered
  margin 0 auto

// home
.home
  display flex

.last-blocks
  flex-flow row wrap

.auto-update
  display flex
  justify-content center
  width 100%

.hero
  margin-top 2rem
  max-width 100%

.last-block
  display flex

  ul.block-data
    margin 0
    padding 0
    flex-flow column nowrap

    li
      width 100% !important

.pending-blocks
  flex 3

.flex-table
  .data-field
    margin 0

.data-field
  justify-content flex-start

td
  .data-field
    justify-content center

.hidden-ctrl
  border none
  margin 0
  padding 0
  background-color inherit
  opacity 0
  width 1px
  height 1px
  position absolute
  