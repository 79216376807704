.ctrl-big-text {
  width: 100%;
  min-width: 100%;
  position: relative;
}
.ctrl-big-text .big-text {
  border: none;
  font-size: 0.75rem;
  overflow-y: scroll;
  overflow-x: auto;
  overflow-wrap: break-word;
  border-radius: 7px;
  width: 100%;
  position: relative;
  min-width: 100%;
  box-sizing: border-box;
  resize: vertical;
  font-family: $monospace-font;
  background: $bg-odd;
}
.ctrl-big-text .content {
  padding: 1em;
  color: #808080;
}
.ctrl-big-text .header {
  box-sizing: border-box;
  padding: 0;
  margin: 0 0 0.25em 0;
  width: 100%;
  display: flex;
  flex: 1;
  list-style: none;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
}
.ctrl-big-text .header div {
  display: flex;
}
.ctrl-big-text .header .title {
  flex: 1;
}
.ctrl-big-text .header .title h3 {
  margin: 0 0 0 1em;
  color: $color;
}
.ctrl-big-text .header .button {
  flex: 0;
  margin: 0 0.5em;
}
/*# sourceMappingURL=src/components/controls/CtrlBigText.css.map */