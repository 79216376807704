svg.svg-icon
  width 1em
  overflow visible

.svg-icon.white
  fill white
.svg-icon .cube
  .bg
    fill white

  .fill
    opacity 0.9

  .top
    fill rgba(255, 255, 255, 0.2)

  .front
    fill rgba(0, 0, 0, 0.3)

  .side
    fill rgba(0, 0, 0, 0.15)

svg.small
  width 0.6em !important

.small .svg-icon
  width 1.125em !important

svg.medium
  width 1.5em !important
