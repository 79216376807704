
@import '@/styles/variables';
.content-short-value, .short-value {
  display: flex;
  position: relative;
}
.content-short-value {
  width: 130px;
  justify-content: flex-end;
  .field-suffix {
    display: flex;
    justify-content: flex-end;
  }
  .short-value {
    display: flex;
    justify-content: flex-end;
    &.suffix-value {
      justify-content: flex-end;
    }
  }
}

.short-value div.full-value {
  display: none;
  position: absolute;
  background-color: $newbw_900;
  padding: 5px;
  z-index: 1;
  color: $white_400;
  width: max-content;
  max-width: max-content;
  word-wrap: break-word;
  height: max-content;
  font-size: 14px;
  bottom: 20px;
  border-radius: 5px;
  &::after {
    border-width: 5px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    z-index: 100;
}
}

.short-value:hover div.full-value {
  display: block;
}
