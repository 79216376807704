.data-page {
  position: relative;
}
.table-ctrls {
  top: 0px;
  right: 24px;
  position: absolute;
  justify-content: flex-end;
}
.unsortable > .field-title .icon svg {
  fill: #808080 !important;
}
.sort button {
  display: flex;
}
.sort div {
  display: flex;
}
.sort .icon {
  margin: 0 0.5em 0 0;
}
.sort .sort-icon {
  margin: 0 0.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color;
  width: 1em;
  height: 1em;
  border-radius: 50%;
}
.sort .sort-icon svg.svg-icon * {
  fill: $bg-color !important;
  display: flex;
}
sub,
.has-sort {
  padding: 0 !important;
}
/*# sourceMappingURL=src/components/General/DataTable.css.map */