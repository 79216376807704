.files-ctrl {
  box-sizing: content-box;
  min-width: 100%;
}
.files-ctrl .files-input {
  display: none;
}
ul.files {
  list-style: none;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  margin: 1em 0 0 0;
}
ul.files li.file {
  font-size: 0.85em;
  display: flex;
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 0 0.5em;
}
ul.files li.file button {
  vertical-align: middle;
  height: auto;
  margin: 0 0.5em;
}
ul.files li.file .file-name {
  margin: 0 0 0 0.5em;
}
/*# sourceMappingURL=src/components/controls/CtrlFiles.css.map */