

.connection-status
  display flex
  flex-flow column nowrap
  color #fbfbfb
  font-size 0.75em
  align-self flex-start
  width 100%
  max-width 100%
  height auto
  margin-bottom 20px
