.data-page {
  margin-top: 40px;
  .messages {
    margin: 20px 0;
    color: $orange_900;
    a {
      margin-left: 4px;
      color: $orange_900;
      text-decoration: underline;
    }
  }
  .content-spiner {
    width: 100%;
  }
  .container-title {
    .title {
      font-size: 32px;
      font-weight: 500;
    }
    display: flex;
    align-items: center;
    svg {
      margin: 0 15px 0 0;
      width: 22px;
      height: 26px;
    }
    .Blocks, .Block, .Addresses{
      width: 18px;
      height: 18px;
      margin: 0 18px 5px 0;
    }
    margin-bottom: 50px;
  }
}

.export-pages {
  width: 350px;
  margin: 0 auto 50px auto;
  border-radius: 12px;
  color: $white_100;
  padding: 24px;

  fieldset {
    margin-top: 10px;
    border-radius: 12px;
    height: 70px;
    .export-items, .export-format {
      padding: 0 12px;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  .export-buttons {
    margin-top: 30px;
    .export {
      padding: 12px 24px;
      color: $white_100;
      border-radius: 6px;
      margin-right: 15px;
    }
    .cancel {
      color: #000;
      background-color: $white_400;
      padding: 12px 24px;
      border-radius: 6px;
    }
  }
}
.ctrl-switch-values {
  display: flex;
  .disabled {
    color: #939393;
  }
}
.switch {
  .ctrl {
    margin: 0 10px;
    position: relative;
    display: inline-block;
    width: 30px;
    height: 15px;
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: .4s;
      border-radius: 34px;
      &::before {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        background-color: white;
        transition: .4s;
        border-radius: 50%;
      }
    }
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    input:checked + .slider:before {
      transform: translateX(16px);
    }
    .slider.round {
      border-radius: 34px;
    }
    .slider.round:before {
      border-radius: 50%;
    }
  }
}

li {
  list-style: none;
}
.pages {
  display: flex;
  justify-content: space-evenly;
  padding: 24px;
  margin: 24px 0;
  color: $white_100;
  .page-numbers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .link {
      cursor: pointer;
      min-width: 18px;
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px;
      text-align: center;
      border-radius: 5px;
      font-size: 14px;
    }
    .next-page, .prev-page {
      width: 18px;
      height: 18px;
      svg {
        width: 18px;
        height: 18px;
      }
      margin: 1px 12px 0 12px;
    }
    
  }
}

.filters {
  margin: 55px 0;
  color: $white_100;
  display: flex;

  small {
    white-space: nowrap;
  }
  .filter-content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .item {
      margin: 0 24px;
    }
  }
}
.checkbox-container {
  display: flex;
  align-items: center;
  justify-items: center;
  input {
    margin-right: 10px;
  }
}

.custom-checkbox {
  opacity: 0;
  position: absolute;
}

.checkmark {
  height: 20px;
  width: 20px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 5px;
  margin-right: 10px;
  display: inline-block;
  position: relative;
  border: 1px solid #575757;
}

.custom-checkbox:checked + .checkmark {
  background-color: orange; 
}

.custom-checkbox:checked + .checkmark:after {
  content: "";
  position: absolute;
  display: block;
  left: 5px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid black;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.auto-update {
  .checkmark {
    height: 18px;
    width: 18px;
  }
  .custom-checkbox:checked + .checkmark:after {
    width: 3px;
    height: 6px;
  }
}
.waiting-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.contract-details {
  color: $white_100;
  .header {
    margin-top: 20px;
  }
  .subtitle {
    margin: 15px 0;
  }

  .ctrl-big-text {
    .content {
      background-color: $newbw_900;
      word-break: break-all;
    }
  }
  .verify {
    .btn {
      margin-top: 24px;
      color: $white_100;
      border: 1px solid #3A3A3A;
      margin-right: 12px;
      padding: 14px 24px;
    }
  }
  .files {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    .link {
      color: rgb(250, 232, 199);
      border-bottom: 1px solid $newbw_800;
      font-weight: 700;

      &.sel {
        color: $pink_900;
        border-bottom: 1px solid $pink_900;
      }
    }
  }
  .source-code {
    background-color: $newbw_900;
    padding: 24px;
    .hljs-comment {
      color: $white_400;
    }
    .hljs-string {
      color: #89DDFF;
    }
    .hljs-literal {
      color: #9afe03;
    }
    .solidity {
      color: #eca5ff;
      .hljs-keyword {
        color: #00bfff;
      }
      .hljs-function {
        color: #fff646;
      }
      .hljs-params {
        color: #d9d9d9;
      }
      .hljs-title {
        color: #40c29b;
      }
      .hljs-number {
        color: #38fae3;
      }
    }
  }
}

.menu-button {
  &.enabled > .btn {
    background-color: #575757;
  }
  position: absolute;
  top: 73px;
  width: 142px;
  &.menu >.btn {
    margin-right: unset !important;
    padding: 12px !important;
    display: flex;
    .btn-title {
      margin: 0 12px;
    }
  }
  &.enabled {
    .icon-down {
      display: none;
    }
    .icon-up {
      display: block
    };
  }
  .icon-up
   {
    display: none;
  }
  .menu-elements {
    position: absolute;
    z-index: 10;
    margin-top: 5px;
    background-color: $newbw_700;
    color: $white_100;
    width: 100%;
    padding: 14px 16px;
    border: 1px solid $newbw_700;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    button {
      display: flex;
      align-items: center;
      color: $white_100;
      svg {
        display: none;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}