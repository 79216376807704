.source-code code {
  font-size: 0.75rem;
  color: $gray;
}
.source-code .line-number {
  font-size: 0.75rem;
  margin: 0 4em 0 0;
  color: $darkness;
}
.source-code .line {
  margin: 0 4em 0.25em 1em;
}
/*# sourceMappingURL=src/components/SourceCode.css.map */