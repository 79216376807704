
.ctrl-big-text
  width 100%
  min-width 100%
  position relative

  .big-text
    border none
    font-size 0.75rem
    overflow-y scroll
    overflow-x auto
    overflow-wrap break-word
    border-radius 7px
    width 100%
    position relative
    min-width 100%
    box-sizing border-box
    resize vertical
    font-family $monospace-font
    background $bg-odd

  .content
    padding 1em
    color gray

  .header
    box-sizing border-box
    padding 0
    margin  0 0 .25em 0
    width 100%
    display flex
    flex 1
    list-style none
    flex-flow row wrap
    justify-content flex-end
    align-items center
    div
      display flex
    .title
      flex 1
      h3
        margin 0 0 0 1em
        color $color
    .button
      flex 0
      margin 0 .5em
