svg.svg-icon {
  width: 1em;
  overflow: visible;
}
.svg-icon.white {
  fill: #fff;
}
.svg-icon .cube .bg {
  fill: #fff;
}
.svg-icon .cube .fill {
  opacity: 0.9;
}
.svg-icon .cube .top {
  fill: rgba(255,255,255,0.2);
}
.svg-icon .cube .front {
  fill: rgba(0,0,0,0.3);
}
.svg-icon .cube .side {
  fill: rgba(0,0,0,0.15);
}
svg.small {
  width: 0.6em !important;
}
.small .svg-icon {
  width: 1.125em !important;
}
svg.medium {
  width: 1.5em !important;
}
/*# sourceMappingURL=src/components/FieldIcon.css.map */