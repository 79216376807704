
@import '../styles/_variables.scss';

.implementation-address-field-container {
  display: flex;
  margin-left: 10px;
  margin-bottom: 15px;
}

.implementation-address-link {
  color: #bbb;
  transition: color 0.3s ease;
  display: flex;
  gap: 5px;
}

.implementation-address-link:hover {
  color: #ddd;
}

.implementation-address-msg {
  color: #bbb;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: $pink_900;
  animation: bounce 0.6s infinite alternate;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  to {
    transform: translateY(-10px);
  }
}
