.waiting-dots .dot {
  fill-color: $color;
}
.waiting-dots .dot-trans-enter-active,
.waiting-dots .dot-trans-leave-active {
  transition: fill-opacity 0.25s;
  fill-opacity: 1;
}
.waiting-dots .dot-trans-enter,
.waiting-dots .dot-trans-leave-to {
  fill-opacity: 0;
}
/*# sourceMappingURL=src/components/WaitingDots.css.map */