
.burp-button
  position relative
  display flex
  flex-flow row nowrap
  justify-content center
  align-items center

  .svg-icon,.icon
    width 1.25em
    height @width

  .message
    position absolute
    z-index 10
    bottom -10px
    opacity 0
    width auto
    white-space nowrap

  .anim
    animation-duration 0.5s
    animation-name rise-message
    animation-timing-function ease-in-out
    opacity 0

    @keyframes rise-message
      0%
        opacity 0

      75%
        transform translateY(-1em)

      80%
        opacity 1

      100%
        opacity 0
        transform translateY(-6em)
