
@import ('../lib/styl/app.styl')

$tip-arrow-size = 5px
$tip-bg = #121212
$tip-border = 1px

.tooltip, .trim
  position relative
  display inline-flex
  overflow visible

// .nowrap
//   white-space nowrap

// Arrow mixin
arrow(pos)
  if (pos == 'top' || (pos == 'bottom'))
    v = 'left'
    vv = 50%
  else
    v = 'bottom'
    vv = 50%

  &:after, &:before
    {pos} 100%
    {v} vv

  &:after
    border-{pos}-color $tip-bg
    margin-{v} $tip-arrow-size * -1

  &:before
    border-{pos}-color @color
    margin-{v} -($tip-arrow-size + $tip-border)

.tooltip
  position relative
  display inline-flex
  flex-flow row nowrap
  justify-content center
  align-items center
  a
    color #B8B8B8
  .copy-button
    .message
      z-index 100
      color #b8b8b8

  .left-button
    margin 0 0 0 .5em
  .tip
    will-change transform opacity
    position absolute
    filter drop-shadow($tip-sh)
    width 100%
    color #b8b8b8
    display flex
    justify-content flex-start // arrow on start
    animation 0.125s ease-in tooltip-anim
    z-index 50

    .value
      border-radius 3px
      padding 0.125em 0.25em
      background-color #121212
      word-break break-all
      display flex
      justify-content center
      align-items center
      min-width 150px

    .tip-txt
      padding 0.25em
      overflow visible
      display inline
      margin 0
      font-size 0.9em
      font-weight normal

  .tip:after, .tip:before
    border solid transparent
    content ' '
    height 0
    width 0
    position absolute
    z-index 100

  .tip:after
    border-width $tip-arrow-size

  .tip:before
    border-width $tip-arrow-size + $tip-border

  // generates tip classes
  for pos in top bottom left right
    .tip.{pos}
      arrow(pos)
  .trim
    a
      color #B8B8B8
  .points
    display inline-flex
    box-shadow none
    width 1rem

    button
      height auto
      width @height
      min-height 2em
      color #b8b8b8

      .icon, .svg-icon
        display inline-flex
        color #b8b8b8
        max-height 1em
        min-width 1em
        justify-content center
        align-items center

  .points.left
    float left
    justify-content flex-start

  .points.right
    float right
    justify-content flex-end

  .copy-txt
    display inline

  button.close
    line-height 1em
    height 1em

    &:after
      top 0.25em !important
      right 0.25em !important
      border-radius 50%
      line-height 1em
      height 1em
      width 1em
      padding 0.25em

.head
  display block

// Copy Animations
.anim
  animation-duration 0.5s
  animation-name copya
  animation-timing-function ease-in-out
  opacity 0

  @keyframes copya
    0%
      opacity 0

    75%
      transform translateY(-1em)

    80%
      opacity 1

    100%
      opacity 0
      transform translateY(-5em)

@keyframes tooltip-anim
  0%
    opacity 0
    transform scale(0.75)

  75%
    transform scale(1)

  100%
    opacity 1

.copying
  animation-duration 1s
  animation-name copyb

@keyframes copyb
  0%
    color #b8b8b8

  100%
    color #b8b8b8
