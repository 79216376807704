
@import '@/styles/variables.scss';

.button {
  margin: 12px 0;
  color: $pink_900;
  border: none;
  border: 1px solid $pink_900;
  border-radius: 12px;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.enabled:hover {
  opacity: .9;
}

.enabled:active {
  background-color: #000;
}

.capitalize {
  text-transform: capitalize;
}

.disabled {
  border: 1px solid #5f5f5f;
  color: #5f5f5f;
  cursor: default;
}

.methods-list {
  flex: 1;
  width: 100%;
}

.methods-category-title {
  margin-bottom: 20px;
}

.method {
  margin-bottom: 10px;
  background-color: $newbw_800;
  border-radius: 8px;
}

.method-name {
  margin-bottom: 10px;
}

.method-input {
  margin: 10px 0;
  justify-content: space-between;
  gap: 3px;
  width: 100%;
}
.method-input .label {
  flex: 1;
}

.label {
  display: inline-flex;
  gap: 2px;
  align-items: center;
  color: #fff;
}

.type {
  font-style: italic;
  font-size: 12px;
}

.method-input-field {
  display: block;
  height: 32px;
  flex: 7;
  margin: 3px 0;
  width: 100%;
  padding: 6px;
  border-radius: 8px;
  box-sizing: border-box;
  outline: none;
  background-color: $newbw_800;
  border: 1px solid #6d6d6d;
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.method-input-field:focus {
  border: 1px solid #b5b5b5;
}

.method-output {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.method-output-value {
  padding: 12px;
  border-radius: 8px;
  background-color: rgba(18, 18, 18, 0.7);
  color: #fff;
  display: flex;
  align-items: center;
  overflow: auto;
}

.interaction-message {
  padding: 5px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.message-info {
  color: #ccc;
}

.message-success {
  color: $cyan_300;
  opacity: .9;
}

.method-output-hash {
  color: white;
  opacity: .8;
  &:hover {
    opacity: 1;
  }
}

.message-error {
  color: $red_500;
}

.result {
  margin-bottom: 10px;
}

.interaction-type-selector-form {
  margin-top: 10px;
}

.interaction-type-options {
  display: flex;
  gap: 20px;
}

.interaction-type-option {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}
