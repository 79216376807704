
@import '@/styles/variables.scss';

.event-details-container {
  flex: 5;
  text-transform: none;
  display: flex;
  gap: 20px;
  justify-content: start;
  align-items: center;
}

.event-details {
  display: inline;

}

.event-args {
  display: inline;
}

.event-arg {
  display: inline;
}

.white-400 {
  color: $white_400;
}

.white-100 {
  color: $white_100;
}

.orange-900 {
  color: $orange_900;
}
